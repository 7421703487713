.app-cursor {
    z-index: 9999;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 2px solid var(--text-color);
    pointer-events: none;
    overflow: hidden;
    position: fixed;
    transform: translate3d(0, 0, 0);
}

// Responsive 
@media screen and (max-width: 768px) {
    .app-cursor {
        display: none;
    }
}